import React from "react"
import { graphql, Link } from "gatsby"
import moment from "moment"
import Img from "gatsby-image"
import renderHTML from "react-render-html"
import Layout from "../components/layout"
import SEO from "../components/seo"
// import Placeholder from "../images/placeholder.jpg"
import "./post.css"
import { Base } from "../utils/animations"
import Typography from "../components/typography"

const PostTemplate = ({ data: { meta, related } }) => {
  return (
    <Layout>
      <SEO
        title={renderHTML(meta.yoast_meta.yoast_wpseo_title)}
        description={renderHTML(meta.yoast_meta.yoast_wpseo_metadesc)}
        ogImage={
          meta.acf.cover_image_post ? meta.acf.cover_image_post.source_url : ""
        }
        slug={`blog/${meta.slug}`}
      />
      <Base className="post">
        <div className="page-wrapper post__container">
          <Base>
            {meta.acf.cover_image_post ? (
              <Img
                className="post__header-image"
                fluid={
                  meta.acf.cover_image_post.localFile.childImageSharp.fluid
                }
                alt={meta.acf.cover_image_post.alt_text}
              />
            ) : (
              <img
                // src={Placeholder}
                alt="placeholder"
                className="post__header-image"
              />
            )}
          </Base>
          <Base>
            <Typography variant="h2">{renderHTML(meta.title)}</Typography>
          </Base>
          <Base className="post__author">
            <Typography variant="body2">
              {`${renderHTML(meta.author.name)} - ${moment(meta.date).format(
                "MMMM Do, YYYY"
              )}`}
            </Typography>
          </Base>
          <Base className="post__content-wrapper">
            {renderHTML(meta.content)}
          </Base>

          {related.edges.length !== 0 ? (
            <>
              <Base className="post__others">
                <Typography variant="h2">You might also like...</Typography>
              </Base>
              <Base className="post__related">
                {related.edges.map(({ node }) => (
                  <Base key={node.slug} className="post">
                    <Link to={`/news/${node.slug}`}>
                      <div className="post__image-container">
                        {node.acf.cover_image_post ? (
                          <Img
                            fluid={
                              node.acf.cover_image_post.localFile
                                .childImageSharp.fluid
                            }
                            alt={renderHTML(
                              node.acf.cover_image_post.alt_text
                                ? node.acf.cover_image_post.alt_text
                                : node.title
                            )}
                          />
                        ) : (
                          <img src="" alt={node.title} />
                        )}
                      </div>
                      <div className="post__content">
                        <Typography variant="h2">
                          {renderHTML(node.title)}
                        </Typography>
                        <Typography variant="body1">
                          {moment(node.date).format("MMMM Do, YYYY")}
                        </Typography>
                      </div>
                    </Link>
                  </Base>
                ))}
              </Base>
            </>
          ) : (
            <div style={{ paddingTop: 100 }} />
          )}
        </div>
      </Base>
    </Layout>
  )
}

export default PostTemplate

export const pageQuery = graphql`
  query($id: String!, $tags: [String]) {
    related: allWordpressPost(
      limit: 2
      filter: { id: { ne: $id }, tags: { elemMatch: { name: { in: $tags } } } }
    ) {
      edges {
        node {
          title
          slug
          date
          acf {
            cover_image_post {
              source_url
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1440, quality: 78) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
    meta: wordpressPost(id: { eq: $id }) {
      slug
      title
      categories {
        name
        id
      }
      tags {
        name
      }
      author {
        name
      }
      modified
      content
      date
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        yoast_wpseo_canonical
      }
      acf {
        cover_image_post {
          source_url
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 1440, quality: 78) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
